<template>
  <div style="height: 100%;">
    <slot
      name="activator"
      :loading="isLoading"
      :on="vOn"
    >
      <v-btn @click="save">
        <v-icon left>
          mdi-content-save
        </v-icon>
        {{ $t('save') }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
import {
  SYSTEM_PREFIX_PROPS,
  SYSTEM_PREFIX_MULTI_LAYER,
  STYLE2D_KEYS,
  FEATURE_MULTI_LAYER,
  getGeojsonLayerStyleByType
} from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DrawerSave',

  data: () => ({
    isLoading: false
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      drawer: state => state.map.drawer.drawer,
      editLayerNode: state => state.map.drawer.editLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),
    hasUpdate: {
      get() {
        return this.$store.state.map.drawer.hasUpdate
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { hasUpdate: newVal })
      }
    },

    vOn() {
      return { click: this.save }
    },
    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  methods: {
    save() {
      const featureCollection = this.drawer.getAll()

      if (
        !this.editLayerNode ||
         !featureCollection.features.length
      ) {
        return
      }

      const firstFeature = this.editLayerNode.source.geoJsonData.features[0]

      this.isLoading = true
      const geometryType = firstFeature.geometry.type
      const style2dProperties = this.getStyle2dProperties(geometryType)
      const firstFeatureProperties = Object.fromEntries(
        Object.keys(
          // 若是multi layer則取first layer properties
          FEATURE_MULTI_LAYER.is(firstFeature)
            ? FEATURE_MULTI_LAYER.getLayerProperties(firstFeature)[0][1]
            : firstFeature.properties
        )
          .filter(
            pKey => !pKey.startsWith(SYSTEM_PREFIX_PROPS) &&
                    !pKey.startsWith(SYSTEM_PREFIX_MULTI_LAYER)
          )
          .map(pKey => [pKey, null])
      )
      featureCollection.features.forEach(feature => {
        delete feature.id
        feature.properties = {
          ...style2dProperties,
          ...firstFeatureProperties,
          ...feature.properties
        }
      })
      const jsonContent = {
        ...this.editLayerNode.source.geoJsonData,
        ...featureCollection
      }
      const fileNode = this.fileTree.findNodeDF(this.fileTree.root, this.editLayerNode.uuid)
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode,
        jsonContent
      })
        .then(() => {
          this.hasUpdate = false
          fileNode.setFileContent(jsonContent)

          this.$emit('fileContentLoaded', {
            fileNode,
            hide: true
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStyle2dProperties(geometryType) {
      const paintType = getGeojsonLayerStyleByType(geometryType)?.type

      return Object.fromEntries(Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(paintType))
        .map(style2dProperty => [
          style2dProperty.key,
          style2dProperty.defaultValue
        ]))
    }
  }
}
</script>

<style lang="scss" scoped></style>
