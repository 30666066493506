<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
        >
          {{ $t('save_as_new_file') }}
        </v-btn>
      </slot>
    </template>
    <v-form
      ref="form"
      v-model="isValid"
      :disabled="isLoading"
      @submit.prevent="submit"
    >
      <v-card class="action-dialog-card">
        <v-card-title class="draggable pa-0 mb-3">
          {{ $t('save_as_new_file') }}
        </v-card-title>

        <v-card-text>
          <v-input
            :class="{'mb-6': !formData.pathFileNode}"
            :value="formData.pathFileNode"
            :rules="rulesPathFileNode"
            hide-details="auto"
          >
            <v-subheader class="px-0">
              <span style="margin-right: 1em;">
                {{ $t('mapset') }}
              </span>
              <FileSelect
                v-model="formData.pathFileNode"
                :select-file-type="FILE_TYPE.MAPSET"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    dark
                    small
                    v-on="on"
                  >
                    {{ $t('select') }}
                  </v-btn>
                </template>
              </FileSelect>
            </v-subheader>
          </v-input>
          <v-text-field
            v-if="formData.pathFileNode"
            class="flex-nowrap mb-6"
            color="primary"
            readonly
            filled
            single-line
            hide-details="auto"
          >
            <template v-slot:label>
              <template v-for="(breadcrumb, iBreadcrumb) in pathFileNodeBreadcrumbs">
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap primary--text"
                  style="font-size: 0.9rem;"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < pathFileNodeBreadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="primary"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </template>
            <template v-slot:append>
              <v-btn
                color="error"
                icon small
                @click="formData.pathFileNode = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            v-model="formData.fileName"
            :label="$t('file_name')"
            filled
            :suffix="fileExtensionName"
            :rules="rulesFileName"
          />
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn
            class="action-btn"
            color="primary"
            rounded
            :loading="isLoading"
            :disabled="!isValid"
            @click="submit"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'
import {
  SYSTEM_PREFIX_PROPS,
  SYSTEM_PREFIX_MULTI_LAYER,
  FILE_TYPE,
  STYLE2D_KEYS,
  FEATURE_MULTI_LAYER,
  getGeojsonLayerStyleByType
} from '@/models/utils'
import {
  validateRequired,
  validateDuplicate
} from '@/assets/js/validate'

import { mapGetters, mapState } from 'vuex'

const fileExtensionName = '.geojson'

const rulesFileName = vm => [
  validateRequired,
  v => validateDuplicate(vm.fileNames)(`${v}${fileExtensionName}`.toLowerCase())
]

const initFormData = () => ({
  fileName: '',
  pathFileNode: null
})

export default {
  name: 'DrawerNewFileDialog',

  components: {
    FileSelect
  },

  data: vm => ({
    FILE_TYPE,
    fileExtensionName,
    dialog: false,
    isValid: false,
    isLoading: false,
    formData: initFormData(),
    rulesPathFileNode: [validateRequired]
  }),

  computed: {
    ...mapState({
      editLayerNode: state => state.map.drawer.editLayerNode,
      drawer: state => state.map.drawer.drawer
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),
    rulesFileName,
    hasUpdate: {
      get() {
        return this.$store.state.map.drawer.hasUpdate
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { hasUpdate: newVal })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    pathFileNodeBreadcrumbs() {
      if (!this.formData.pathFileNode) {
        return []
      }

      return this.formData.pathFileNode.breadcrumbs
    },
    fileNames() {
      const mapset = this.formData.pathFileNode

      if (!mapset) return []

      return mapset.children
        .map(file => file.name?.toLowerCase?.())
        .filter(Boolean)
    }
  },

  watch: {
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      this.formData = initFormData()
    },
    cancel() {
      this.dialog = false

      this.init()
    },
    getStyle2dProperties(geometryType) {
      const paintType = getGeojsonLayerStyleByType(geometryType)?.type

      return Object.fromEntries(Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(paintType))
        .map(style2dProperty => [
          style2dProperty.key,
          style2dProperty.defaultValue
        ]))
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      const featureCollection = this.drawer.getAll()

      if (!featureCollection.features.length) {
        return
      }

      const firstFeature = this.editLayerNode
        ? this.editLayerNode.source.geoJsonData.features[0]
        : featureCollection.features[0]

      this.isLoading = true
      const geometryType = firstFeature.geometry.type
      const style2dProperties = this.getStyle2dProperties(geometryType)
      const firstFeatureProperties = Object.fromEntries(
        Object.keys(
          // 若是multi layer則取first layer properties
          FEATURE_MULTI_LAYER.is(firstFeature)
            ? FEATURE_MULTI_LAYER.getLayerProperties(firstFeature)[0][1]
            : firstFeature.properties
        )
          .filter(
            pKey => !pKey.startsWith(SYSTEM_PREFIX_PROPS) &&
                    !pKey.startsWith(SYSTEM_PREFIX_MULTI_LAYER)
          )
          .map(pKey => [pKey, null])
      )
      featureCollection.features = featureCollection.features.map(feature => {
        delete feature.id
        feature.properties = {
          ...style2dProperties,
          ...firstFeatureProperties,
          ...feature.properties
        }
        return feature
      })
      const jsonContent = {
        ...(this.editLayerNode?.source?.geoJsonData || {}),
        ...featureCollection
      }
      return this.$store.dispatch('files/createMaplayer', {
        project: this.project,
        parent: this.formData.pathFileNode,
        maplayerName: `${this.formData.fileName}${fileExtensionName}`
      })
        .then(fileNode => {
          this.hasUpdate = false
          return this.$store.dispatch('files/updateMaplayerData', {
            project: this.project,
            fileNode,
            jsonContent
          })
        })
        .then(() => {
          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }
}

.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
